<template>
  <div class="fa">
    <!-- 对战 -->
    <!-- <div class="title t-c color-white">对战</div>
    <div class="shuoming t-c color-green weight">对战说明</div>-->
    <div class="left d-flex">
      <div
        class="allpk"
        @click="$router.push({ name: 'Pk' }), change('allpk')"
        :class="color == 'allpk' ? 'active' : ''"
      >
        全部房间
      </div>
      <!-- @click="$message.error('正在维护中！')" -->

      <div
        class="create"
        @click="$router.push({ name: 'create' }), change('create')"
      >
        创建房间
      </div>
      <div
        class="pklog"
        @click="$router.push({ name: 'pklog' }), change('pklog')"
        :class="color == 'pklog' ? 'active' : ''"
      >
        历史记录
      </div>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getToken } from "../../api/cookie";
import { getHomeBanner } from "../../api/index";
export default {
  data() {
    return {
      allpk: true,
      createpk: false,
      pklog: false,
      imgs: [],
      color: "",
    };
  },
  mounted() {
    getHomeBanner().then((res) => {
      this.imgs = res.data.rows;
    });
  },
  methods: {
    createPK() {
      let token = getToken();
      //跳转之前先进行判断
      if (!token) {
        this.$bus.$emit("createpk", true);
      } else {
        this.$router.push({ name: "create" });
      }
    },
    change(state) {
      if (state == "allpk") {
        this.color = state;
        this.allpk = true;
        this.createpk = false;
        this.pklog = false;
      } else if (state == "create") {
        this.color = state;
        this.allpk = false;
        this.createpk = true;
        this.pklog = false;
      } else if (state == "pklog") {
        this.color = state;
        this.allpk = false;
        this.createpk = false;
        this.pklog = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fa {
  width: 1130px;
  height: 460px;
  margin: 50px auto;
  background: url("~@/assets/images/home/mainallbg.png") no-repeat;
  background-size: 100% 100%;

  @media (max-width: 550px) {
    width: 100%;
    height: 710px;
    margin: 0px;
    overflow: hidden;
    background-image: none;
  }

  .left {
    border-top: 1px solid transparent;
    width: 600px;
    margin: 0 auto 35px;
    padding-top: 25px;
    color: white;
    justify-content: space-evenly;
    font-weight: 700;
    .active {
      color: gold;
    }
    .header {
      display: none;
    }

    @media (max-width: 550px) {
      display: block;
      border: none;
      padding: 0px;
      margin: 0px;

      .allpk {
        display: none;
      }

      .create {
        display: none;
      }

      .pklog {
        display: none;
      }

      .header {
        display: block;

        .swiper {
          width: 50%;
          margin-left: 40px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .nav {
          width: 60%;
          height: 90px;
          margin-top: 20px;
          margin-left: 8px;
          display: flex;
          justify-content: space-around;

          .fuli {
            flex: none;
            width: 70px;
            height: 100%;

            .fu-h {
              height: 70px;
              width: 100%;
              background-image: url("../../assets/images/home/phone/bg10086.png");
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                display: block;
                width: 60%;
                height: 60%;
              }
            }

            div {
              margin-top: -4px;
              text-align: center;
              font-size: 16px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
